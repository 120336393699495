<!--Section: Products v.2-->
<section class="section pb-3">
  <div class="row">
    <div class="col-md-6">
      <blockquote class="blockquote float-left bq-primary">
        <h3 class="bq-title">
          {{ translate.data["BESTPRODUCTS"] | translate }}
        </h3>
      </blockquote>
    </div>
    <div class="col-md-6">
      <a
        class="btn btn-default float-right"
        [routerLink]="['/products/all-products']"
        >{{ translate.data["VIEWALL"] | translate }}</a
      >
    </div>
  </div>
  

  <div *ngIf="loading">
    <app-card-loader [loop]="4" [height]="500"></app-card-loader>
  </div>

  <owl-carousel
    *ngIf="!loading"
    [options]="options"
    [items]="bestProducts"
    [carouselClasses]="['owl-theme', 'row', 'sliding']"
  >
    <!--First column-->
    <div
      class="item"
      style="padding: 20px;"
      *ngFor="let product of bestProducts; let i = index"
    >
      <div class="mb-r">
        <!--Card-->
        <div class="card card-cascade wider">
          <!--Card image-->
          <a [routerLink]="['/products/product', product.$key]">
            <div class="view overlay hm-white-slight">
              <img
                [src]="product.productImageUrl"
                class="img-fluid"
                alt=""
                width="360px"
                height="640px"
              />
              <a>
                <div
                  class="mask waves-effect waves-light"
                  mdbRippleRadius
                ></div>
              </a>
            </div>
          </a>
          <!--/.Card image-->

          <!--Card content-->
          <div class="card-body text-center no-padding">
            <!--Category & Title-->
            <a href="" class="text-muted">
              <h5>{{ product.productCategory }}</h5>
            </a>
            <h5 class="card-title">
              <strong>
                <a [routerLink]="['/products/product', product.$key]">{{
                  product.productName
                }}</a>
              </strong>
            </h5>

            <!--Description-->
            <p class="card-text">{{ product.productDescription }}</p>

            <!--Card footer-->
            <div class="card-footer">
              <span class="left">₹ {{ product.productPrice }}</span>
              <span class="right">
                <a
                  placement="top"
                  mdbTooltip="Add to Wishlist"
                  container="body"
                >
                  <i class="fa fa-heart"></i>
                </a>
              </span>
            </div>
          </div>
          <!--/.Card content-->
        </div>
        <!--/.Card-->
      </div>
      <!--/First column-->
    </div>
  </owl-carousel>

<h4>Video </h4>
<br>
<div class="row">
  <div class="col-sm-5">
    <iframe width="420" height="345" src="https://www.youtube.com/embed/CFicwLeVVQQ">
    </iframe>
  </div>
  <div class="col-sm-5">
    <br>
    <h4>Vietnam Factory Machining Automatic Lathe CINCOM A32</h4>
  <br>
    <p>Introducing the equipment of AIZAKI VIETNAM. I will learn Japanese that I am not used to and introduce it with all my might.</p>
    <p>Aizaki Co., Ltd. 1-16-25 Sumisakaminami, Suzaka City, Nagano Prefecture 382-0098</p>
    <p>TEL: 026-245-5881 URL: http://www.aizaki.co.jp/</p>
  </div>
  
</div>

<br>
<div class="row">
  <div class="col-sm-5">
    <iframe width="420" height="345" src="https://www.youtube.com/embed/_6AHDNZZe_8">
    </iframe>
  </div>
  <div class="col-sm-5">
    <br>
    <h4>AIZAK Vietnam Factory Introduction Precision Machining Machined Parts</h4>
    <br>
    <p>AIZAKI VIETNAM CO.,LTD</p>
    <p>Address：104/2-10,street 4A,Long Binh(Amata) industrial park,</p> 
    <p>Long Binh Ward,Bien Hoa city,Dong Nai province,Vietnam.</p>
    <p>TEL：+84-251-393-6202 FAX：+84-251-393-6203</p>
  </div>
  
  <br>
</div>
<div class="row">
  <div class="col-sm-5">
    <iframe width="420" height="345" src="https://www.youtube.com/embed/BdRIQeTmaeM">
    </iframe>
  </div>
  <div class="col-sm-5">
    <br>
    <h4>AIZAK Vietnam Factory Introduction Precision Machining Machined Parts</h4>
    <br>
    <p>AIZAKI VIETNAM CO.,LTD</p>
    <p>Address：104/2-10,street 4A,Long Binh(Amata) industrial park,</p> 
    <p>Long Binh Ward,Bien Hoa city,Dong Nai province,Vietnam.</p>
    <p>TEL：+84-251-393-6202 FAX：+84-251-393-6203</p>
  </div>
  
</div>

<br>
<div class="row">
  <div class="col-sm-5">
    <iframe width="420" height="345" src="https://www.youtube.com/embed/dAmCPPZGSTQ">
    </iframe>
  </div>
  <div class="col-sm-5">
    <br>
    <h4>AIZAK Vietnam Factory Introduction Precision Machining Machined Parts</h4>
    <br>
    <p>AIZAKI VIETNAM CO.,LTD</p>
    <p>Address：104/2-10,street 4A,Long Binh(Amata) industrial park,</p> 
    <p>Long Binh Ward,Bien Hoa city,Dong Nai province,Vietnam.</p>
    <p>TEL：+84-251-393-6202 FAX：+84-251-393-6203</p>
  </div>

  
</div>


</section>
<!--Section: Products v.2-->
