import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-index",
  templateUrl: "./index.component.html",
  styleUrls: ["./index.component.scss"],
})
export class IndexComponent implements OnInit {
  carouselList = [
    {
      bannerImg: "./assets/banner_img/img_1.jpg",
      title: "PRECISION MACHINING",
      description: "We offer a variety of CNC machine solutions which have been built from our 100 years of experience and the highest technology to satisfy customer’s demands. ",
    },
    {
      bannerImg: "./assets/banner_img/img_3.jpg",
      title: "MATERIALS",
      description:
        "We can process all kinds of material including SUS, Aluminum, Iron, Copper, Brass, Die-casting metal, Harden steel and so on",
    },
    {
      bannerImg: "./assets/banner_img/img_4.jpg",
      title: "PLUG & JACK PRODUCTION ",
      description: "Our plug jack is one of important parts that helping life of many people in various areas such as emergency phones at the medical related industries",
    },
  ];

  constructor() {}

  ngOnInit() {}
}
