export const FireBaseConfig = {
  apiKey: "AIzaSyBzp02xVYWYenCuPAlj7LLDSKbkoE5pfoI",
  authDomain: "gm-korean.firebaseapp.com",
  databaseURL: "https://gm-korean-default-rtdb.firebaseio.com",
  projectId: "gm-korean",
  storageBucket: "gm-korean.appspot.com",
  messagingSenderId: "158770744884",
  appId: "1:158770744884:web:c31b32276749d0ed228461",
  measurementId: "G-07VY2E4GG9"
};
