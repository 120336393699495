


<nav class="navbar-collapse">
  <div class="view">
    <img
      src="https://imagemetfone.viettelglobal.net/1623315689586.png"
      alt="First slide"
      width="350px"
      height="90px"
    />
  </div>
</nav>
<!--Navbar-->
<nav class="navbar navbar-expand-lg navbar-dark">
  <!-- Navbar brand -->
  <!-- <a class="navbar-brand" href="javascript:;;" [routerLink]="['/']">AizakiVN</a> -->


  <!-- Collapse button -->
  <button
    class="navbar-toggler"
    type="button"
    data-toggle="collapse"
    data-target="#basicExampleNav"
    aria-controls="basicExampleNav"
    aria-expanded="false"
    aria-label="Toggle navigation"
  >
    <span class="navbar-toggler-icon"></span>
  </button>

  <!-- Collapsible content -->
  <div class="collapse navbar-collapse" id="basicExampleNav">
    <!-- Links -->
    <ul class="navbar-nav mr-auto">
      <li class="nav-item">
        <a class="nav-link" href="javascript:;;" [routerLink]="['/']"
          >{{ this.translate.data["HOME"] | translate }}
          <span class="sr-only">(current)</span>
        </a>
      </li>
      <li class="nav-item">
        <a
          class="nav-link"
          href="jaascript:;;"
          [routerLink]="['/introduce/introduce']"
          >{{ this.translate.data["INTRODUCE"] | translate }}</a
        >
      </li>
      <li class="nav-item">
        <a
          class="nav-link"
          href="jaascript:;;"
          [routerLink]="['/ability/all-ability']"
          >{{ this.translate.data["ABILITY"] | translate }}</a
        >
      </li>
      <li class="nav-item">
        <a
          class="nav-link"
          href="jaascript:;;"
          [routerLink]="['/products/all-products']"
          >{{ this.translate.data["PRODUCTS"] | translate }}</a
        >
      </li>
      <li class="nav-item">
        <a
          class="nav-link"
          href="jaascript:;;"
          [routerLink]="['/faq/all-faq']"
          >{{ this.translate.data["FAQ"] | translate }}</a
        >
      </li>
      <li class="nav-item">
        <a
          class="nav-link"
          href="jaascript:;;"
          [routerLink]="['/task-board/tasks']"
          >{{ this.translate.data["TASKBOARD"] | translate }}</a
        >
      </li>
      <li class="nav-item">
        <a
          class="nav-link"
          href="jaascript:;;"
          [routerLink]="['/recruitment/all-recruitment']"
          >{{ this.translate.data["CAREERS"] | translate }}</a
        >
      </li>
      <!-- <li class="nav-item">
        <a class="nav-link" href="#">Pricing</a>
      </li> -->
    </ul>
    <!-- Links -->

    <ul class="navbar-nav ml-auto">
      <li class="nav-item">
        <div>
          <a
            class="nav-link"
            href="javascript:;;"
            [routerLink]="['/products/favourite-products']"
          >
            <i class="fa fa-heart">
              <span class="mt-2">
                {{ productService.getLocalFavouriteProducts().length }}</span
              >
            </i>
          </a>
        </div>
      </li>
      <li class="nav-item">
        <div>
          <a
            class="nav-link"
            href="javascript:;;"
            [routerLink]="['/products/cart-items']"
          >
            <i class="fa fa-shopping-cart">
              <span class="mt-2">
                {{ productService.getLocalCartProducts().length }}</span
              >
            </i>
          </a>
        </div>
      </li>
      <!-- <li class="nav-item" *ngIf="authService.isLoggedIn$ | async">
        <a class="nav-link" [routerLink]="['/users']">
          <i class="fa fa-user mr-1"></i
          >{{ this.translate.data["MYACCOUNT"] | translate }}</a
        >
      </li> -->

      <li class="nav-item dropdown" dropdown>
        <a
          dropdownToggle
          type="button"
          class="nav-link dropdown-toggle waves-light"
        >
          <i class="fa fa-paint-brush"> </i><span class="caret"></span
        ></a>
        <div
          *dropdownMenu
          class="dropdown-menu dropdown dropdown-primary color-dropdown"
          role="menu"
        >
          <div class="row p-2" style="margin: 0;">
            <ng-container
              *ngFor="let theme of colorPallet1"
              [ngTemplateOutlet]="themeTemplate"
              [ngTemplateOutletContext]="{
                item: theme
              }"
            ></ng-container>
          </div>
          <div class="row p-2" style="margin: 0;">
            <ng-container
              *ngFor="let theme of colorPallet2"
              [ngTemplateOutlet]="themeTemplate"
              [ngTemplateOutletContext]="{
                item: theme
              }"
            ></ng-container>
          </div>
        </div>
      </li>

      <li class="nav-item dropdown" dropdown>
        <a
          dropdownToggle
          type="button"
          class="nav-link dropdown-toggle waves-light"
        >
          <i class="fa fa-globe"> </i><span class="caret"></span
        ></a>
        <div
          *dropdownMenu
          class="dropdown-menu dropdown dropdown-primary"
          role="menu"
        >
          <a
            class="dropdown-item waves-light"
            href="javascript:;;"
            (click)="setLang(lang.langCode)"
            *ngFor="let lang of languageList"
          >
            <i class="fa fa-flag-o mr-2"></i>
            {{ lang.language }} ({{ lang.langCode }})</a
          >
        </div>
      </li>
     
      <!-- <li class="nav-item" *ngIf="authService.isLoggedOut$ | async">
        <a class="nav-link" [routerLink]="['/login']">
          <i class="fa fa-sign-in mr-1"></i>
          {{ this.translate.data["SIGNIN"] | translate }}</a
        >
      </li>
      <li class="nav-item" *ngIf="authService.isLoggedIn$ | async">
        <a class="nav-link" (click)="logout()">
          <i class="fa fa-sign-in mr-1"></i
          >{{ this.translate.data["LOGOUT"] | translate }}</a
        >
      </li> -->
      <li class="nav-item">
        <a href="javascript:;;" class="nav-link"
          >Login/Sign up</a
        >
      </li>
    </ul>
  </div>
  <!-- Collapsible content -->
</nav>


<!-- Theme Template -->
<ng-template #themeTemplate let-item="item">
  <div
    class="col-sm hover-effect"
    data-toggle="tooltip"
    data-placement="bottom"
    [title]="item.title"
    style="padding: 0;"
  >
    <div class="color-container">
      <div
        class="colorDiv {{ item.color }}"
        (click)="updateTheme(item.id)"
      ></div>
    </div>
  </div>
</ng-template>
