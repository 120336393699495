<!--Footer-->
<footer class="page-footer indigo center-on-small-only mt-3">
  <!--Copyright-->
  <div class="footer-copyright">
    <div class="container-fluid">
      © 2018 Copyright:
      <a href="mailto:ikismail7@gmail.com"> Mohammed Ismail </a>

      <span style="float: right;">
        <i class="fa fa-globe"></i>
        Website:
        <a href="http://ikismail.github.io" target="blank">ikismail</a>
      </span>
    </div>
  </div>
  <!--/.Copyright-->
</footer>
<!--/.Footer-->
