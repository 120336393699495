<br />
<div class="container" *ngIf="favoruiteProducts.length > 0">
  <div class="row">
    <!-- List of Products -->
    <div class="col-sm-4" *ngFor="let product of favoruiteProducts">
      <div class="item">
        <div class="mb-r">
          <!--Card-->
          <div class="card card-cascade wider">
            <!--Card image-->
            <a [routerLink]="['/products/product', product.$key]">
              <div class="view overlay hm-white-slight">
                <img
                  [src]="product.productImageUrl"
                  class="img-fluid"
                  alt=""
                  width="360px"
                  height="640px"
                />
                <a>
                  <div
                    class="mask waves-effect waves-light"
                    mdbRippleRadius
                  ></div>
                </a>
              </div>
            </a>

            <!--/.Card image-->

            <!--Card content-->
            <div class="card-body text-center no-padding">
              <!--Category & Title-->
              <a class="text-muted">
                <h5>{{ product.productCategory }}</h5>
              </a>
              <h5 class="card-title">
                <strong>
                  <a [routerLink]="['/products/product', product.$key]">{{
                    product.productName
                  }}</a>
                </strong>
              </h5>

              <!--Description-->
              <p class="card-text">{{ product.productDescription }}</p>

              <!--Card footer-->
              <div class="card-footer">
                <span class="left">₹ {{ product.productPrice }}</span>
                <span class="right">
                  <a
                    placement="top"
                    mdbTooltip="Quick Look"
                    container="body"
                    [routerLink]="['/products/product', product.$key]"
                  >
                    <i class="fa fa-eye"></i>
                  </a>
                  <a
                    placement="top"
                    mdbTooltip="Remove Product"
                    container="body"
                    (click)="removeFavourite(product)"
                  >
                    <i class="fa fa-trash"></i>
                  </a>
                </span>
              </div>
            </div>
            <!--/.Card content-->
          </div>
          <!--/.Card-->
        </div>
      </div>
    </div>
  </div>
</div>

<div class="container" *ngIf="favoruiteProducts.length === 0">
  <app-no-products-found
    [title]="messageTitle"
    [description]="messageDescription"
  ></app-no-products-found>
</div>
