<h4 class="d-flex justify-content-between align-items-center mb-3">
  <span class="text-muted">Your cart</span>
  <span class="badge badge-primary badge-pill">{{ products.length }}</span>
</h4>
<ul class="list-group mb-3">
  <li
    class="list-group-item d-flex justify-content-between lh-condensed"
    *ngFor="let product of products"
  >
    <div>
      <h6 class="my-0">{{ product.productName }}</h6>
    </div>
    <span class="text-muted">₹ {{ product.productPrice }}</span>
  </li>
  <hr />
  <li class="list-group-item d-flex justify-content-between">
    <span>Total (INR)</span>
    <strong>₹ {{ totalValue }}</strong>
  </li>
  <a class="btn btn-primary" [routerLink]="['/products/all-products']"
    >Continue Shipping</a
  >
  <a class="btn btn-default" [routerLink]="['/checkouts']">Checkout</a>
</ul>
