import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-no-access",
  template: `<p>
    no-access works!
  </p>`,
  styleUrls: [],
})
export class NoAccessComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
